* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  background-color: #ffffff;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

.mobile-container {
  width: 100%;
  max-width: 496px;
  margin: 0 auto;
  padding: 0 24px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.cursor {
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-col {
  flex-direction: column;
}

.jc-center {
  justify-content: center;
}

.jc-between {
  justify-content: space-between;
}

.jc-around {
  justify-content: space-around;
}

.jc-start {
  justify-content: flex-start;
}

.jc-end {
  justify-content: flex-end;
}

.ai-center {
  align-items: center;
}

.ai-start {
  align-items: flex-start;
}

.ai-end {
  align-items: flex-end;
}

.ai-baseline {
  align-items: baseline;
}

.ai-stretch {
  align-items: stretch;
}

.dialog-small {
  width: 100%;
  max-width: 432px;
}

.dialog-medium {
  width: 100%;
  max-width: 600px;
}

.br-round {
  border-radius: 50%;
}

.capitalize {
  text-transform: capitalize;
}

.underline {
  text-decoration: underline;
}

@media only screen and (min-width: 1025px) {
  .hidden-desktop {
    display: none !important;
  }

  #ada-entry .ada-button-frame {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .hidden-mobile {
    display: none !important;
  }

  #ada-entry .ada-button-frame {
    display: none;
  }
}

/* Override Finicity(BV provider) iframe style */
#finicityConnectIframe {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999 !important;
  background: rgba(0, 0, 0, 0.8);
}
